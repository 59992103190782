import CssBaseline from '@mui/material/CssBaseline'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import App, { AppContext, AppProps } from 'next/app'
import Head from 'next/head'
import { getSession, SessionProvider } from 'next-auth/react'
import { SnackbarProvider } from 'notistack'
import React, { useEffect } from 'react'
import "tabulator-tables/dist/css/tabulator.min.css";
import { SWRConfig } from 'swr'
import Header from '../components/Header'
import theme from '../src/theme'
import '../styles/index.scss'
import fetcher from '../utils/swrFetcher'
import { Session } from 'next-auth'
import { useCurrentVersionStore } from '../stores/currentVersionStore'
import { useUserOrganizationStore } from '../stores/user-organization-store'

type TelemetryAppProps = AppProps & { session: Session | null }

function TelemetryApp({ Component, pageProps, session }: TelemetryAppProps) {
  const version = useCurrentVersionStore((state) => state.data)
  const currentUserLoaded = useCurrentVersionStore((state) => state.loadingComplete)
  const currentVersionFetch = useCurrentVersionStore((state) => state.fetch)
  const loggedOut = session === null
  const orgLoading = useUserOrganizationStore((state) => state.loading)
  const orgLoadingComplete = useUserOrganizationStore((state) => state.loadingComplete)
  const fetchOrgs = useUserOrganizationStore((state) => state.fetch)

  useEffect(() => {
    if (!orgLoadingComplete && !orgLoading) {
      void fetchOrgs()
    }
  }, [orgLoadingComplete, orgLoading, fetchOrgs])

  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles)
    }
  }, [])

  useEffect(() => {
    if (!currentUserLoaded && !loggedOut) {
      currentVersionFetch()
    }
  }, [currentVersionFetch, currentUserLoaded, loggedOut])

  return (
    <>
      <Head>
        <title>NGI Live</title>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
      </Head>
      <SWRConfig
        value={{
          fetcher: fetcher,
          suspense: true,
          revalidateOnFocus: false,
          shouldRetryOnError: false,
          dedupingInterval: 10000,
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <SnackbarProvider maxSnack={5}>
                <CssBaseline />
                {loggedOut ? <Component {...pageProps} /> : (
                  <SessionProvider session={session}>
                    <Header userName={session.user?.name || ''} userEmail={session.user?.email || ''} />
                    <div className={'content'}>
                      <Component {...pageProps} />
                    </div>
                  </SessionProvider>
                )}
              </SnackbarProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </LocalizationProvider>
      </SWRConfig>
      <footer className="footer">
        <div>{version ? `NGI LIVE ADMIN v${version}` : ''}</div>
      </footer>
    </>
  )
}

TelemetryApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext)
  const { ctx } = appContext
  if (ctx === null) {
    return { ...appProps, session: null }
  }
  const session = await getSession(ctx)
  const request = ctx.req
  if (
    session === null &&
    request?.url !== '/login' &&
    request?.url !== '/api/auth/error' &&
    request?.url !== '/error'
  ) {
    ctx.res.writeHead(302, { Location: '/login' })
    ctx.res.end()
  } else if (session !== null && request?.url === '/login') {
    ctx.res.writeHead(302, { Location: '/' })
    ctx.res.end()
  }
  return { ...appProps, session }
}

export default TelemetryApp
